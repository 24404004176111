// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-author-tsx": () => import("./../src/layouts/author.tsx" /* webpackChunkName: "component---src-layouts-author-tsx" */),
  "component---src-layouts-auto-template-tsx": () => import("./../src/layouts/auto-template.tsx" /* webpackChunkName: "component---src-layouts-auto-template-tsx" */),
  "component---src-layouts-blog-post-tsx": () => import("./../src/layouts/blog-post.tsx" /* webpackChunkName: "component---src-layouts-blog-post-tsx" */),
  "component---src-layouts-blog-tsx": () => import("./../src/layouts/blog.tsx" /* webpackChunkName: "component---src-layouts-blog-tsx" */),
  "component---src-layouts-category-tsx": () => import("./../src/layouts/category.tsx" /* webpackChunkName: "component---src-layouts-category-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-tsx": () => import("./../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-friends-tsx": () => import("./../src/pages/friends.tsx" /* webpackChunkName: "component---src-pages-friends-tsx" */),
  "component---src-pages-glossary-tsx": () => import("./../src/pages/glossary.tsx" /* webpackChunkName: "component---src-pages-glossary-tsx" */),
  "component---src-pages-hub-bcn-tsx": () => import("./../src/pages/hub-bcn.tsx" /* webpackChunkName: "component---src-pages-hub-bcn-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-playbook-tsx": () => import("./../src/pages/playbook.tsx" /* webpackChunkName: "component---src-pages-playbook-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-projects-we-love-tsx": () => import("./../src/pages/projects-we-love.tsx" /* webpackChunkName: "component---src-pages-projects-we-love-tsx" */)
}

