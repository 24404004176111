module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://akasha.matomo.cloud","siteUrl":"https://akasha.org"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AKASHA Foundation","short_name":"AKASHA","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"/opt/buildhome/repo/src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"282e4ee76c076a465453365248b21643"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
